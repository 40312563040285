/* eslint-disable eqeqeq */
import {buildProperty, buildSchema, EntityCallbacks,} from "@camberi/firecms";
import * as firestore from "firebase/firestore";

type Practice = {
    category: string;
    practice: string;
    tradition: string;
    thumbnail: string;
    slug: string;
    status: string;
    tags: string[];
    createdAt: Date;
    publishedAt: Date;
    updatedAt: Date;
}

const buildPracticeSchema = (schemaTitle: string) => {
    return buildSchema<Practice>({
            name: schemaTitle,
            properties: {
                status: {
                    title: "Status",
                    dataType: "string",
                    config: {
                        enumValues: {
                            "draft": "Draft",
                            "published": "Published"
                        }
                    }
                },
                publishedAt: ({values}) => buildProperty({
                    title: "Published date",
                    dataType: "timestamp",
                    description: "If date is not set but status is Published - the practice will be posted immediately.",
                    disabled: (
                        values.status === "published" ? false : {
                            clearOnDisabled: true,
                        }
                    )
                }),
                category: {
                    title: "Category",
                    validation: {required: true},
                    description: "Category of the practice",
                    dataType: "string",
                },
                practice: {
                    title: "Practice",
                    validation: {required: true},
                    description: "Name of the practice",
                    dataType: "string",
                },
                tradition: {
                    title: "Tradition",
                    validation: {required: true},
                    description: "Tradition of the practice",
                    dataType: "string",
                },
                slug: {
                    title: "Slug",
                    dataType: "string",
                    validation: {unique: true},
                    config: {
                        url: true
                    }
                },
                thumbnail: {
                    title: "Thumbnail",
                    dataType: "string",
                    config: {
                        storageMeta: {
                            mediaType: "image",
                            storagePath: "article-thumbnail",
                            acceptedFiles: ["image/*"],
                            metadata: {
                                width: {
                                    min: 200,
                                    max: 400,
                                },
                                height: {
                                    min: 200,
                                    max: 400,
                                },
                                cacheControl: "max-age-1000000"
                            },
                        },
                    }
                },
                tags: {
                    title: "Tags",
                    dataType: "array",
                    of: {
                        dataType: "reference",
                        path: "tags"
                    }
                },
                createdAt: {
                    title: "Created Date",
                    validation: {required: true},
                    description: "When the article is created",
                    dataType: "timestamp",
                    autoValue: "on_create",
                    readOnly: true
                },
                updatedAt: {
                    title: "Updated Date",
                    validation: {required: true},
                    description: "When the article is updated",
                    dataType: "timestamp",
                    autoValue: "on_update",
                    readOnly: true
                },
            }
        }
    );
}

export const practicesCallbacks: EntityCallbacks = {
    onPreSave: ({
                    values
                }) => {
        if (values.slug == null || values.slug == "") {
            values.slug = '/practices/' + values.headline.split(' ').join('-').toLowerCase().replace(/[^a-zA-Z/-]/g, "");
        }
        if (values.status == null || values.status == "") {
            values.status = 'draft';
        }

        if (values.status == "published" && (values.publishedAt == null)) {
            values.publishedAt = firestore.Timestamp.now();
        }

        return values;
    }
}

export const practicesSectionsSchema = buildSchema({
    name: "Sections",
    properties: {
        paywall: {
            title: "Paywall",
            validation: {required: false},
            dataType: "boolean"
        },
        order: {
            title: "Order",
            validation: {required: true},
            dataType: "number",
        },
        media: buildProperty({
            title: "Media",
            validation: {required: false},
            dataType: "map",
            columnWidth: 400,
            properties: {
                type: {
                    title: "Image caption",
                    dataType: "string",
                },
                value: {
                    title: "Image",
                    dataType: "string",
                }
            }
        }),
        content: buildProperty({
            title: "Content",
            validation: {required: true},
            dataType: "array",
            columnWidth: 400,
            oneOf: {
                typeField: "type",
                valueField: "value",
                properties: {
                    text: {
                        title: "Text",
                        validation: {required: true},
                        description: "The main content displayed in the practice",
                        dataType: "string",
                        config: {
                            markdown: true,
                        }
                    },
                    linked_content: {
                        dataType: "map",
                        title: "Linked content",
                        description: "Explore more about this content",
                        properties: {
                            cta: {
                                title: "CTA",
                                dataType: "string",
                            },
                            description: {
                                title: "Description",
                                dataType: "string",
                                description: "Description about these related content",
                            },
                            link: {
                                title: "Link",
                                dataType: "string",
                                description: "Link to the related article or practice",
                            },
                            thumbnail: {
                                title: "Thumbnail",
                                dataType: "string",
                                description: "Thumbnail for preview",
                            }
                        },
                    }
                }
            }
        }),
    }
})

export {buildPracticeSchema}
export type {Practice}
