import React from "react";

import {User as FirebaseUser} from "firebase/auth";
import {
    Authenticator,
    buildCollection,
    FirebaseCMSApp,
    NavigationBuilder,
} from "@camberi/firecms";

import "typeface-rubik";
import "typeface-space-mono";
import {articleCallbacks, articleLocaleSchema, buildArticleSchema} from "./schemas/ArticleSchema";
import {authorSchema} from "./schemas/common/AuthorSchema";
import {tagSchema} from "./schemas/common/TagSchema";
import {namesSchema} from "./schemas/NamesSchema";
import {buildEventSchema} from "./schemas/EventsSchema";
import {buildQuotesSchema, quotesCallbacks} from "./schemas/QuotesSchema";
import {promoCardsSchema} from "./schemas/YouExploringSchema";
import textSearchController from "./search-controllers/SearchController";
import {
    buildPracticeSchema,
    practicesCallbacks,
    practicesSectionsSchema
} from "./schemas/PracticeSchema";

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

// TODO: Replace with production credentials
// const firebaseConfig = {
//     apiKey: "AIzaSyBLiKbGrsQ9Ekhxm5YfBCsFBeCDtC9G-1U",
//     authDomain: "sol-v2-app.firebaseapp.com",
//     projectId: "sol-v2-app",
//     storageBucket: "sol-v2-app.appspot.com",
//     messagingSenderId: "283310644358",
//     appId: "1:283310644358:web:50bc79b351ddba1aab8507",
//     measurementId: "G-LRE5EDQ6WC"
// };

const firebaseConfig = {
    apiKey: "AIzaSyDlWcGryB6qzsygO6Mjsy0yaw1ReEPqYBg",
    authDomain: "sol-v2-stage.firebaseapp.com",
    projectId: "sol-v2-stage",
    storageBucket: "sol-v2-stage.appspot.com",
    messagingSenderId: "49684730100",
    appId: "1:49684730100:web:480f5d49676d9275888ea1",
    measurementId: "G-P2PWDTHWH5"
  };

export default function App() {

    const navigation: NavigationBuilder = async () => {
        return ({
            collections: [
                buildCollection({
                    path: "articles",
                    schema: buildArticleSchema('Article'),
                    name: "Articles",
                    callbacks: articleCallbacks,

                    pagination: 5,
                    exportable: true,
                    permissions: ({authController}) => ({
                        edit: true,
                        create: true,
                        delete: authController.extra.roles.includes("admin")
                    }),
                    textSearchEnabled: true,
                    subcollections: [
                        buildCollection({
                            name: "Locales",
                            path: "locales",
                            schema: articleLocaleSchema,
                        })
                    ]
                }),
                buildCollection({
                    path: "practices",
                    schema: buildPracticeSchema('Practice'),
                    name: "Practices",
                    callbacks: practicesCallbacks,
                    pagination: 5,
                    exportable: true,
                    permissions: ({authController}) => ({
                        edit: true,
                        create: true,
                        delete: authController.extra.roles.includes("admin")
                    }),
                    textSearchEnabled: true,
                    subcollections: [
                        buildCollection({
                            name: "Sections",
                            path: "sections",
                            schema: practicesSectionsSchema,
                        })
                    ]
                }),
                buildCollection({
                    path: "promo-cards",
                    schema: promoCardsSchema,
                    name: "Promo Cards",
                    permissions: ({authController}) => ({
                        edit: true,
                        create: true,
                        delete: authController.extra.roles.includes("admin")
                    }),

                }),
                buildCollection({
                    path: "names",
                    schema: namesSchema,
                    name: "Names",
                    permissions: ({authController}) => ({
                        edit: true,
                        create: true,
                        delete: authController.extra.roles.includes("admin")
                    }),
                    textSearchEnabled: true,
                    pagination: 15,
                }),
                buildCollection({
                    path: "events",
                    schema: buildEventSchema(),
                    name: "Events",
                    permissions: ({authController}) => ({
                        edit: true,
                        create: true,
                        delete: authController.extra.roles.includes("admin")
                    }),
                }),
                buildCollection({
                        path: "quotes",
                        schema: buildQuotesSchema(),
                        name: "Quotes",
                        callbacks: quotesCallbacks,
                        exportable: true,
                        permissions: ({authController}) => ({
                            edit: true,
                            create: true,
                            delete: authController.extra.roles.includes("admin")
                        }),
                    }
                ),
                buildCollection({
                    path: "pages",
                    schema: buildArticleSchema('Page'),
                    name: "Pages",
                    callbacks: articleCallbacks,
                    exportable: true,
                    textSearchEnabled: true,
                    subcollections: [
                        buildCollection({
                            name: "Locales",
                            path: "locales",
                            schema: articleLocaleSchema,
                        })
                    ],
                    permissions: ({authController}) => ({
                        edit: true,
                        create: true,
                        delete: authController.extra.roles.includes("admin")
                    }),
                }),
                buildCollection({
                    name: "Authors",
                    path: "authors",
                    schema: authorSchema,
                }),
                buildCollection({
                    name: "Tags",
                    path: "tags",
                    schema: tagSchema,
                }),
            ]
        });
    };

    const myAuthenticator: Authenticator<FirebaseUser> = async ({
                                                                    user,
                                                                    authController
                                                                }) => {

        const firebaseApp = firebase.initializeApp(firebaseConfig);
        const db = firebaseApp.firestore();

        const userDocs = await db
            .collection('users')
            .where('email', '==', user?.email)
            .limit(1)
            .get();

        if (userDocs.docs.length === 0) {
            throw Error("Unfortunately you are not allowed to view this content. Ask an administrator for an access.");
        }

        const role = userDocs.docs[0].data().role;
        const isHaveAccess = role === 'admin' || role === 'author';
        if (!isHaveAccess) {
            throw Error("Unfortunately you are not allowed to view this content. Ask an administrator for an access.");
        }

        const sampleUserData = {
            roles: [role]
        };
        authController.setExtra(sampleUserData);
        return true;
    };

    return <FirebaseCMSApp
        name={"Sol CMS"}
        authentication={myAuthenticator}
        navigation={navigation}
        firebaseConfig={firebaseConfig}
        textSearchController={textSearchController}
    />;
}
