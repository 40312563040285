import { FirestoreTextSearchController } from "@camberi/firecms";
import Typesense from 'typesense';

const textSearchController: FirestoreTextSearchController =
    ({ path, searchString }) => {
        if (path === "articles")
            return performTypesenseSearch("articles", searchString, 'headline, tagsNames, authorsNames, subheading');
        if (path === "pages")
            return performTypesenseSearch("pages", searchString, 'headline, tagsNames, subheading');
        if (path === "names")
            return performTypesenseSearch("names", searchString, 'name, rootName');
        return undefined;
    };

async function performTypesenseSearch(index: string, searchString: string, queryBy: string) {
    const TypesenseClient = new Typesense.Client({
        'nodes': [
            {
                'host': 'zs9xpdhcwfir3qj7p.a1.typesense.net',
                'port': 443,
                'protocol': 'https'
            }
        ],
        'apiKey': 'M0sv6Chmdpdwq74yGPKPrFSmAdnGw8ek',
        'connectionTimeoutSeconds': 2
    });



    const results = await TypesenseClient.collections(index).documents().search({ q: searchString, query_by: queryBy });

    let output: string[] = [];

    results.hits?.forEach(hit => {
        const document = hit.document as any;
        output.push(document.id);
    })

    return output;
}


export default textSearchController;