import {buildSchema} from "@camberi/firecms";

type Names = {
    description: string;
    fullOrigin: string;
    gender: string;
    meaning: string;
    name: string;
    rootName: string;
    verified: string;
};

const namesSchema = buildSchema<Names>({
    name: "Names",
    properties: {
        name: {
            title: "Name",
            dataType: "string"
        },
        description: {
            title: "Description",
            dataType: "string",
        },
        fullOrigin: {
            title: "Full Origin",
            dataType: "string",
        },
        gender: {
            title: "Gender",
            dataType: "string",
            config: {
                enumValues: {
                    "m": "Male",
                    "f": "Female",
                    "m & f": "Male and Female"
                }
            }
        },
        meaning: {
            title: "Meaning",
            dataType: "string",
        },
        rootName: {
            title: "Root name",
            dataType: "string",
        },
        verified: {
            title: "Verified",
            dataType: "string"
        }
    }
});

export {namesSchema};
export type {Names};