/* eslint-disable eqeqeq */
import { Author } from "./common/AuthorSchema";
import { buildProperty, buildSchema, EntityCallbacks, } from "@camberi/firecms";

import * as firestore from "firebase/firestore";


const locales = {
    "en-US": "English (United States)",
    "es-ES": "Spanish (Spain)",
    "de-DE": "German"
}

type Article = {
    createdAt: Date;
    publishedAt: Date;
    updatedAt: Date;
    author: Author[];
    headline: string;
    subheading: string;
    content: any[];
    slug: string;
    tags: string[];
    status: string;
    thumbnail: string;
}

const buildArticleSchema = (schemaTitle: string) => {
    return buildSchema<Article>({
        name: schemaTitle,
        properties: {
            status: {
                title: "Status",
                dataType: "string",
                config: {
                    enumValues: {
                        "draft": "Draft",
                        "published": "Published"
                    }
                }
            },
            publishedAt: ({ values }) => buildProperty({
                title: "Published date",
                dataType: "timestamp",
                description: "If date is not set but status is Published - the article will be posted immediately.",
                disabled: (
                    values.status === "published" ? false : {
                        clearOnDisabled: true,
                    }
                )
            }),
            headline: {
                title: "Headline",
                validation: { required: true },
                description: "Heading of the page",
                dataType: "string",
            },
            subheading: {
                title: "Subheading",
                dataType: "string",

            },
            thumbnail: {
                title: "Thumbnail",
                dataType: "string",
                config: {
                    storageMeta: {
                        mediaType: "image",
                        storagePath: "article-thumbnail",
                        acceptedFiles: ["image/*"],
                        metadata: {
                            width: {
                                min: 200,
                                max: 400,
                            },
                            height: {
                                min: 200,
                                max: 400,
                            },
                            cacheControl: "max-age-1000000"
                        },
                    },
                }
            },
            content: buildProperty({
                title: "Content",
                validation: { required: true },
                dataType: "array",
                columnWidth: 400,
                oneOf: {
                    typeField: "type",
                    valueField: "value",
                    properties: {
                        images: {
                            title: "Images",
                            dataType: "array",
                            of: {
                                dataType: "map",
                                validation: { required: true },
                                properties: {
                                    image_caption: {
                                        title: "Image caption",
                                        dataType: "string",
                                    },
                                    image: {
                                        title: "Image",
                                        dataType: "string",
                                        config: {
                                            storageMeta: {
                                                mediaType: "image",
                                                storagePath: "images",
                                                acceptedFiles: ["image/*"],
                                                metadata: {
                                                    cacheControl: "max-age-1000000"
                                                }
                                            }
                                        }
                                    }
                                }
                            },
                            description: "This field allows to upload multiple images at once and reordering"
                        },
                        text: {
                            title: "Text",
                            validation: { required: true },
                            description: "The main content displayed in the article",
                            dataType: "string",
                            config: {
                                markdown: true,

                            }
                        },
                        quick_facts: {
                            title: "Quick facts",
                            description: "A table with quick facts",
                            dataType: "map",
                            properties: {
                                title: {
                                    dataType: "string",
                                    title: "Title",
                                },
                                table: {
                                    dataType: "array",
                                    title: "Table",
                                    of: {
                                        dataType: "map",
                                        properties: {
                                            key: {
                                                title: "Key",
                                                dataType: "string",
                                            },
                                            value: {
                                                title: "Value",
                                                dataType: "string"
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        video: {
                            title: "Video",
                            description: "YouTube link",
                            dataType: "map",
                            properties: {
                                video_url: {
                                    title: "Video URL",
                                    dataType: "string",
                                    validation: { required: true },
                                },
                                duration: {
                                    title: "Duration",
                                    dataType: "number",
                                    description: "Duration of the video in seconds",
                                }
                            },
                        },
                        linked_content: {
                            dataType: "map",
                            title: "Linked content",
                            description: "Articles that related to this article.",
                            properties: {
                                cta: {
                                    title: "CTA",
                                    dataType: "string",
                                    description: "Call to action",
                                },
                                description: {
                                    title: "Description",
                                    dataType: "string",
                                    description: "Description",
                                },
                                link: {
                                    title: "Link",
                                    dataType: "string",
                                    description: "Link",
                                },
                                thumbnail: {
                                    title: "thumbnail",
                                    dataType: "string",
                                    config: {
                                        storageMeta: {
                                            mediaType: "image",
                                            storagePath: "article-thumbnail",
                                            acceptedFiles: ["image/*"],
                                            metadata: {
                                                cacheControl: "max-age-1000000"
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    },

                }
            }
            ),

            tags: {
                title: "Tags",
                dataType: "array",
                of: {
                    dataType: "reference",
                    path: "tags"
                }
            },
            author: {
                title: "Author",
                description: "List of authors",
                dataType: "array",
                of: {
                    dataType: "reference",
                    path: "authors",
                }
            },
            createdAt: {
                title: "Created Date",
                validation: { required: true },
                description: "When the article is created",
                dataType: "timestamp",
                autoValue: "on_create",
                readOnly: true
            },
            updatedAt: {
                title: "Updated Date",
                validation: { required: true },
                description: "When the article is updated",
                dataType: "timestamp",
                autoValue: "on_update",
                readOnly: true
            },
            slug: {
                title: "Slug",
                dataType: "string",
                validation: { unique: true },
                config: {
                    url: true
                }
            }
        }
    }
    );

}


export const articleCallbacks: EntityCallbacks = {
    onPreSave: ({
        schema,
        path,
        entityId,
        values,
        status
    }) => {
        if (values.slug == null || values.slug == "") {
            values.slug = '/article/' + values.headline.split(' ').join('-').toLowerCase().replace(/[^a-zA-Z/-]/g, "");
        }
        if (values.status == null || values.status == "") {
            values.status = 'draft';
        }

        if (values.status == "published" && (values.publishedAt == null || values.publishedAt == undefined)) {
            values.publishedAt = firestore.Timestamp.now();
        }
        return values;
    }
}

export const articleLocaleSchema = buildSchema({
    customId: locales,
    name: "Locale",
    properties: {
        headline: {
            title: "Headline",
            validation: { required: true },
            dataType: "string"
        },
        subheading: {
            title: "Subheading",
            dataType: "string",

        },
        content: buildProperty({
            title: "Content",
            validation: { required: true },
            dataType: "array",
            columnWidth: 400,
            oneOf: {
                typeField: "type",
                valueField: "value",
                properties: {
                    images: {
                        title: "Images",
                        dataType: "array",
                        of: {
                            dataType: "map",
                            validation: { required: true },
                            properties: {
                                image_caption: {
                                    title: "Image caption",
                                    dataType: "string",
                                },
                                image: {
                                    title: "Image",
                                    dataType: "string",
                                    config: {
                                        storageMeta: {
                                            mediaType: "image",
                                            storagePath: "images",
                                            acceptedFiles: ["image/*"],
                                            metadata: {
                                                cacheControl: "max-age-1000000"
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        description: "This field allows to upload multiple images at once and reordering"
                    },
                    text: {
                        title: "Text",
                        validation: { required: true },
                        description: "The main content displayed in the article",
                        dataType: "string",
                        config: {
                            markdown: true,

                        }
                    },
                    video: {
                        title: "Video",
                        description: "YouTube link",
                        dataType: "string",
                        validation: { url: true }
                    },
                    linked_content: {
                        dataType: "array",
                        title: "Linked content",
                        description: "Articles that related to this article.",
                        oneOf: {
                            typeField: "type",
                            valueField: "value",
                            properties: {
                                article: {
                                    title: "Article",
                                    dataType: "reference",
                                    path: "articles"
                                },
                                page: {
                                    title: "Page",
                                    dataType: "reference",
                                    path: "pages"
                                },

                            }
                        }
                    }
                }
            }
        }),

    }
})


export { buildArticleSchema }
export type { Article }
