import {buildSchema} from "@camberi/firecms";

type Author = {
    name: string;
    bio: string;
    url: string;
}

const authorSchema = buildSchema<Author>({
    name: "Authors",
    properties: {

        name: {
            title: "Name",
            validation: {required: true},
            dataType: "string",
        },
        bio: {
            title: "Bio",
            dataType: "string",
            config: {
                markdown: true,
            }
        },
        url: {
            title: "Link",
            dataType: "string",
            config: {
                url: true,
            }
        }
    }
});

export {authorSchema};
export type {Author};
