import { buildSchema } from "@camberi/firecms";

type EventType = {
    tradition: string;
    timestamp: Date;
    year: number;
    month: number;
    day: number;
    headline: string;
    description: string;
    image: string;
}

const buildEventSchema = () => {
    return buildSchema<EventType>({
        name: "Events",
        properties: {
            tradition: {
                title: "Tradition",
                dataType: "string",
            },
            timestamp: {
                title: "Timestamp",
                dataType: "timestamp",
            },
            year: {
                title: "Year",
                dataType: "number",
            },
            month: {
                title: "Month",
                dataType: "number",
            },
            day: {
                title: "Day",
                dataType: "number",
            },
            headline: {
                title: "Headline",
                dataType: "string",
            },
            description: {
                title: "Description",
                dataType: "string",
            },
            image: {
                title: "Image",
                dataType: "string",
            },
        }
    });

}

export { buildEventSchema }
export type { EventType }