import { buildSchema, EntityReference } from "@camberi/firecms";

type PromoCard = {
    status: string;
    tag: EntityReference;
    cta: string;
    description: string;
    link: string;
    thumbnail: string;
};

const promoCardsSchema = buildSchema<PromoCard>({
    name: "Promo Cards",
    properties: {
        status: {
            title: "Status",
            dataType: "string",
            config: {
                enumValues: {
                    "draft": "Draft",
                    "published": "Published"
                }
            }
        },
        tag: {
            title: "Tag",
            dataType: "reference",
            path: "tags",
        },
        cta: {
            title: "CTA",
            dataType: "string",
        },
        description: {
            title: "Description",
            dataType: "string",
        },
        link: {
            title: "Link",
            dataType: "string",
        },
        thumbnail: {
            title: "Thumbnail",
            dataType: "string",
            config: {
                storageMeta: {
                    mediaType: "image",
                    storagePath: "article-thumbnail",
                    acceptedFiles: ["image/*"],
                    metadata: {
                        width: {
                            min: 200,
                            max: 400,
                        },
                        height: {
                            min: 200,
                            max: 400,
                        },
                        cacheControl: "max-age-1000000"
                    },
                },
            }
        },
    }
});

export { promoCardsSchema };
export type { PromoCard };