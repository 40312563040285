import {buildSchema} from "@camberi/firecms";

type Tag = {
    name: string;
    category: string;
    status: string;
}


const tagSchema = buildSchema<Tag>({
    name: "Tags",
    properties: {

        name: {
            title: "Tag",
            validation: {required: true},
            dataType: "string"
        },
        category: {
            title: "Category",
            dataType: "string",
            config: {
                enumValues: {
                    "tradition": "Tradition",
                    "topic": "Topic",
                    "style": "Style",
                    "internal": "Internal",
                }
            }
        },
        status: {
            title: "Status",
            dataType: "string",
            config: {
                enumValues: {
                    "published": "Published",
                    "draft": "Draft",
                }
            }
        }
    }
});

export {tagSchema}
export type {Tag}