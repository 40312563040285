import { buildProperty, buildSchema, EntityCallbacks } from "@camberi/firecms";

import * as firestore from "firebase/firestore";


type Quotes = {
    author: string;
    quote: string;
    createdAt: Date;
    updatedAt: Date;
    publishedAt: Date;
    ranId: number;
    status: string;
    tags: string[];
    kicker: string;
    category: string;
}

const buildQuotesSchema = () => {
    return buildSchema<Quotes>({
        name: "Quotes",
        properties:
        {
            status: {
                title: "Status",
                dataType: "string",
                config: {
                    enumValues: {
                        "draft": "Draft",
                        "published": "Published"
                    }
                }
            },
            author: {
                dataType: "string",
                validation: {
                    required: true
                },
                title: "Author",
            },
            quote: {
                title: "Quote",
                dataType: "string",
                validation: {
                    required: true
                }
            },
            publishedAt: ({ values }) => buildProperty({
                title: "Published date",
                dataType: "timestamp",
                description: "If date is not set but status is Published - the quote will be posted immediately.",
                disabled: (
                    values.status === "published" ? false : {
                        clearOnDisabled: true,
                    }
                )
            }),
            tags: {
                title: "Tags",
                dataType: "array",
                of: {
                    dataType: "reference",
                    path: "tags"
                }
            },
            createdAt: {
                title: "Created Date",
                validation: { required: true },
                description: "When the article is created",
                dataType: "timestamp",
                autoValue: "on_create",
                readOnly: true
            },
            updatedAt: {
                title: "Updated Date",
                validation: { required: true },
                description: "When the article is updated",
                dataType: "timestamp",
                autoValue: "on_update",
                readOnly: true
            },
            ranId: {
                title: "Random ID",
                dataType: "number",
                validation: { required: true, unique: true, min: 0, max: 1000000 },
                description: "Random ID",
            },
            kicker: {
                title: "Kicker",
                dataType: "string",
                validation: { required: true },
                description: "Kicker",
            },
            category: {
                title: "Category",
                dataType: "string",
                validation: {
                    required: true
                },
            }
        }
    });
}

export const quotesCallbacks: EntityCallbacks = {
    onPreSave: ({ schema,
        path,
        entityId,
        values,
        status }) => {
        if (values.status == null || values.status == "") {
            values.status = 'draft';
        }

        if (values.status == "published" && (values.publishedAt == null || values.publishedAt == undefined)) {
            values.publishedAt = firestore.Timestamp.now();
        }
        return values;
    }
}
export { buildQuotesSchema }
export type { Quotes }